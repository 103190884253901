// Bootsrtap themes colors
// =======================
$primary: #007bff; // ==== $primary  ====
$secondary: #6c757d; // ==== secondary ====
$tertiary: #6f42c1; // ==== tertiary ====
$success: #28a745; // ==== success   ====
$info: #17a2b8; // ==== info      ====
$warning: #ffc107; // ==== warning   ====
$danger: #dc3545; // ==== danger    ====
$light: #f8f9fa; // ==== light     ====
$dark: #343a40; // ==== dark      ====

// ====================  HandOff Colors
$primary: #3dbeb4; // ==== $primary  ====
$secondary: #6c3ac2; // ==== secondary ====
// $tertiary: #0F2837; // ==== tertiary ====
$success: #0aa66d; // ==== success   ====
$info: #009bda; // ==== info      ====
$warning: #f89e22; // ==== warning   ====
$danger: #e61f36; // ==== danger    ====
$light: #ffffff; // ==== light     ====
$dark: #071927; // ==== dark      ====

// Body
//
// Settings for the `<body>` element.
$body-bg: #ffffff;
$input-bg: $body-bg;
$body-color: $dark;
$gray: #a1a1a1;

// Links
// Style anchor elements.
$link-color: $primary;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;

// Texts
$txt: $body-color;
$txt2: $light;
$txt3: $gray;
$icons: #f7ffe8;
$section-bg: #f4f5f7;
$header-color: #fff2f8;

// ==================== UX Pallete color
$pallete1: $primary !default;

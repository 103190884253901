@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "../node_modules/flatpickr/dist/flatpickr.min.css";
@import "../node_modules/ngx-spinner/animations/ball-scale-multiple.css";
@import "../node_modules/intl-tel-input/build/css/intlTelInput.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "src/assets/scss/settings/_colors.scss";
@import "../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css";
swiper {
  overflow: hidden;
}

.grecaptcha-badge {
  visibility: hidden;
}

ngx-editor {
  ul {
    list-style: disc;
  }
  *:not([style]) {
    text-align: left;
    direction: ltr;
  }
}

.ratio-wrapper {
  position: relative;

  &::before {
    content: "";
    display: table;
    width: 100%;
    padding-top: 50%;
  }
  .ratio-wrapper__el {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.square {
    &::before {
      padding-top: 100%;
    }
  }
}

.iti {
  width: 100%;
}

.english-digits {
  .rtl & {
    unicode-bidi: plaintext;
    text-align: right;

    input {
      unicode-bidi: plaintext;
      text-align: right;
    }
  }
}

.pac-container {
  text-align: left;
  direction: ltr;
}

.NgxEditor__Content {
  min-height: 100px;
}

.radio-btns {
  .radio {
    margin: 0;
    input {
      display: none;

      &:checked ~ span {
        background-color: rgba($color: $primary, $alpha: 0.1);
      }
    }

    span {
      border: 1px solid $primary;
      border-radius: 8px;
      padding: 0 30px;
      height: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: 0.3s;
    }

    & + .radio {
      margin-inline-start: 10px;
    }
  }
}

.details-page {
  .form-control,
  .ng-select .ng-select-container {
    border: 0;
    pointer-events: none;
    padding: 0;
    height: auto !important;
    min-height: initial !important;
    background: none;
  }

  .optionalChoice,
  .ng-clear-wrapper,
  .ng-arrow-wrapper {
    display: none;
  }

  .event-calendar__header {
    justify-content: center;
  }

  .event-calendar__body {
    pointer-events: none;
    .cal-day-times {
      .btn {
        display: none !important;
      }
    }
  }
}

.skeleton {
  pointer-events: none;
}
.skeleton-box {
  height: 1em;
  display: inline-block;
  width: 100%;
  position: relative;
  overflow: hidden;
  pointer-events: none;
  border-radius: 25px;
  background-color: #eee;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: shimmerLTR;
    content: "";

    .rtl & {
      animation-name: shimmerRTL;
      transform: translateX(100%);
    }
  }

  &.skeleton-box__image {
    height: 100%;
    width: 100%;
    border-radius: 0;
  }
}
@keyframes shimmerLTR {
  100% {
    transform: translateX(100%);
  }
}
@keyframes shimmerRTL {
  100% {
    transform: translateX(-100%);
  }
}

ngb-timepicker {
  .rtl & {
    direction: ltr;
    display: flex;
    justify-content: flex-end;
  }
}

.btn {
  i:not(:only-child) {
    margin-inline-end: 10px;
  }
}

.collapse {
  visibility: inherit;
}

.link {
  cursor: pointer;
}

.message {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  img {
    width: 15rem;
  }

  .icon {
    // background-color: rgba($color: $secondaryColor, $alpha: 0.1);
    width: 10rem;
    height: 10rem;
    border-radius: 57% 43% 37% 63% / 39% 48% 52% 61%;
    // border: 1px solid $secondaryColor;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 3rem;
    margin: 0 auto;
    svg {
      fill: $primary;
    }
  }

  img {
    width: 150px;
  }

  h2 {
    margin: 2rem 0 1rem;
    font-size: 3rem;
  }

  .btn {
    margin-top: 3rem;
  }

  &--h {
    display: flex;
    align-items: center;
    justify-content: center;

    .details {
      margin-inline-start: 4rem;
      text-align: right;

      [dir="ltr"] & {
        text-align: left;
      }
    }

    .btn {
      margin-top: 1rem;
    }
  }

  &--error {
    .icon {
      // background-color: rgba($color: $red, $alpha: 0.1);
      // border-color: $red;
      svg {
        fill: $danger;
      }
    }
  }
}

.modal-content {
  border-radius: 16px !important;
  margin-left: 40px;
  margin-right: 40px;
}

//lighthouse custom design
.lb-outerContainer {
  position: static !important;
}
.lb-nav {
  position: absolute !important;
  top: 45% !important;
  padding-left: 3%;
  padding-right: 3%;
}
.lb-data .lb-close {
  display: block;
  position: absolute;
  right: 10%;
  top: 0px;
  background-image: url("assets/images/tickets/lighthouse-close.png") !important;
  width: 40px !important;
  height: 40px !important;
  opacity: 1 !important;
  @media screen and (max-width: 768px) {
    right: 0;
    top: -10%;
  }
}

.lb-nav a.lb-next {
  background-image: url("assets/images/tickets/lighthouse-next.png") !important;
  width: 40px !important;
  height: 40px !important;
  opacity: 1 !important;
}

.lb-nav a.lb-prev {
  background-image: url("assets/images/tickets/lighthouse-next.png") !important;
  width: 40px !important;
  transform: rotate(180deg);
  height: 40px !important;
  opacity: 1 !important;
}

.lb-data .lb-details {
  max-width: 100% !important;
  float: none !important;
  .lb-number {
    color: white;
    font-size: 14px;
    direction: ltr;
  }
  text-align: center !important;
}

.view-btn {
  color: $primary;
}
//  this class I add specially only for admin-accounts in admin COMP for cotumClassForEmailChecker //
.email-width {
  width: 137px !important;
}

i.fa-chevron-right:dir(ltr) {
  transform: rotate(180deg);
}
